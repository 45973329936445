import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { HttpClient } from '@angular/common/http';
//import { LoginUser } from '../models/user';

@Injectable()
export class InvitationService {

  private readonly url = {
    main: 'invitation',
    checkHash: 'invitation/check-hash',
    urlHash: 'invitation/url-hash',
    create: 'invitation/create',
    industries: 'invitation/industries',
    organizations: 'invitation/organizations',
  };

  constructor(private http: HttpClient) { }

  checkHash(hash: string): Observable<any> {
    const url = `${this.url.checkHash}/${hash}`;
    return this.http.get(url);
  }

  getUrlHash(type: string): Observable<any> {
    const url = `${this.url.urlHash}/${type}`;
    return this.http.get(url);
  }

  create(data:any, hash: any): Observable<any> {
    const dataGuest=Object.assign(data, hash);
    const headers = {successToaster: 'Create Success'};
    return this.http.post<any>(`${this.url.create}`, dataGuest, {headers});
  }

  getIndustries(): Observable<any> {
    const url = `${this.url.industries}`;
    return this.http.get(url);
  }

  getOrganizations(): Observable<any> {
    const url = `${this.url.organizations}`;
    return this.http.get(url);
  }


}
